import { Action, createReducer, on } from '@ngrx/store';
import { orderBy } from 'lodash';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) => {
    if (state.ids.length) {
      return {
        ...state,
        isLoading: false,
      };
    } else {
      return fromState.featureAdapter.setAll(orderBy(items, 'name'), {
        ...state,
        isLoading: false,
      });
    }
  }),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export function countriesReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
