import { createAction, props } from '@ngrx/store';

import { LookupCountry } from '../../models';

export const loadRequest = createAction('[coutries] Load Countries Request');

export const loadSuccess = createAction(
  '[coutries] Load Countries Success',
  props<{ items?: LookupCountry[] }>(),
);

export const loadFailure = createAction(
  '[coutries] Load Countries Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[countries] Reset State');
