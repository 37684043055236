import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CountriesStoreEffects } from './effects';
import { countriesReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('countries', countriesReducer),
    EffectsModule.forFeature([CountriesStoreEffects]),
  ],
})
export class CountriesStoreModule {}
