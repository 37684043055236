import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { LookupCountry } from '../../models';
import { CountriesService } from '../../services/countries.service';
import { RootState } from '../root-state';

import * as fromActions from './actions';

@Injectable()
export class CountriesStoreEffects {
  constructor(
    private dataService: CountriesService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private store: Store<RootState>,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      withLatestFrom(this.store),
      exhaustMap(([_, rootStore]: [any, RootState]) => {
        const countriesIds = get(rootStore, 'countries.ids', []);
        if (countriesIds.length) {
          return of(fromActions.loadSuccess({}));
        }
        return this.dataService.load().pipe(
          map((response: IResponseSuccess<LookupCountry[]>) => {
            return fromActions.loadSuccess({
              items: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );
}
