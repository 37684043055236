import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';
import { UiModule } from '../../ui/ui.module';

import { PlacesFormComponent } from './places-form/places-form.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    UiModule,
    NgPipesModule,
  ],
  declarations: [PlacesFormComponent],
  exports: [PlacesFormComponent],
})
export class PlacesModule {}
