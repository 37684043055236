import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get, isEqual, sortBy } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { IResponseSuccess } from '../../../core/models/response-sucess.model';
import { autoHidePlacesFn, isRequiredField } from '../../../helpers';
import { Places, PlacesInputIdsKey, PlacesKey } from '../../../models';
import { PlacesCustomRequired } from '../../../models/objects/places-custom-required';
import {
  CountriesStoreActions,
  CountriesStoreSelectors,
} from '../../../root-store/countries-store';
import { RootState } from '../../../root-store/root-state';
import { NotificationService } from '../../../ui/services/notification.service';
import { IPlace } from '../models/place.model';
import { PlacesService } from '../services/places.service';

type enabledInputsFn = (places: Places) => {
  country: boolean;
  state: boolean;
  county: boolean;
  city: boolean;
};

@Component({
  selector: 'by-places-form',
  templateUrl: './places-form.component.html',
  styleUrls: ['./places-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlacesFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PlacesFormComponent,
      multi: true,
    },
  ],
})
export class PlacesFormComponent
  implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit
{
  @Input() templateMode = false;

  @Input() type = 'generic';

  @Input() id = 0;

  @Input() ngValidators = true;

  @Input() skip = 1;

  @Input() overrideLabel = null;

  @Input() formDisplay = 'initial';

  @Input() allowClear = true;

  @Input() nzLayout: any = null;

  @Input() flexItems = false;

  @Input() customFormClass = '';

  @Input() reload = false;

  @Input()
  showValidationMessages = true;

  @Input() set byDisabled(disabled: boolean) {
    Object.keys(this.form.controls).forEach((controlKey) => {
      if (disabled) {
        this.form.get(controlKey).disable({ emitEvent: false });
      } else {
        this.form.get(controlKey).enable({ emitEvent: false });
      }
    });
  }

  @Input() set autoHidePlaces(autohide: boolean) {
    this.autoHide = autohide;
    if (autohide) {
      this.subs.add(
        this.form.valueChanges.subscribe((places) => {
          this.enabledInputs = autoHidePlacesFn(places);
        }),
      );
    }
  }

  @Input('customRequired') set _customRequired(
    customRequired: PlacesCustomRequired,
  ) {
    Object.keys(customRequired || {})?.forEach((key) => {
      const validators = get(customRequired[key], [1], []);
      this.form.get(key).setValidators(validators);
      this.form.get(key).updateValueAndValidity();
    });
  }

  @Input() required = false;

  @Input() collapsed = false;

  @Input() set place(place: any) {
    if (!place) {
      return;
    }

    setTimeout(() => {
      this.form.patchValue({ countryId: get(place, 'countryId') });
      this.form.patchValue({ stateId: get(place, 'stateId') });
      this.form.patchValue({ countyId: get(place, 'countyId') });
      this.form.patchValue({ cityId: get(place, 'cityId') });
    });
  }

  @Input('enabledInputs') set _enabledInputs(
    enabledInputs:
      | {
          country: boolean;
          state: boolean;
          county: boolean;
          city: boolean;
        }
      | enabledInputsFn,
  ) {
    if (!(enabledInputs instanceof Function)) {
      this.enabledInputs = enabledInputs;
    } else {
      this.enableInputsFunction = enabledInputs;
      this.subs.add(
        this.form.valueChanges.subscribe((places) => {
          this.enabledInputs = enabledInputs(places);
        }),
      );
    }
  }

  @Output() changePlace = new EventEmitter<any>();

  @ViewChild('countryTpl') countryTpl: TemplateRef<any>;

  @ViewChild('cityTpl') cityTpl: TemplateRef<any>;

  @ViewChild('countyTpl') countyTpl: TemplateRef<any>;

  @ViewChild('stateTpl') stateTpl: TemplateRef<any>;

  searcCity$ = new Subject<string>();

  enableInputsFunction: enabledInputsFn;

  autoHide = false;

  countryIdKeys: PlacesInputIdsKey[] = [
    'countryId',
    'stateId',
    'countyId',
    'cityId',
  ];

  enabledInputs = {
    country: true,
    state: true,
    county: true,
    city: true,
  };

  countries$ = this.store.pipe(select(CountriesStoreSelectors.selectAllItems));

  form: UntypedFormGroup;

  citySearched: string;

  isUserTyping = false;

  countries: IPlace[] = [];

  states: IPlace[] = [];

  counties: IPlace[] = [];

  cities: IPlace[] = [];

  searchLoading = false;

  isRequiredField = isRequiredField;

  private subs = new SubSink();

  onChange;
  onTouched;

  globalData;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private placesService: PlacesService,
    private store: Store<RootState>,
    private translate: TranslateService,
    private notifications: NotificationService,
  ) {
    this.form = this.formBuilder.group({
      countryId: [1],
      stateId: [null],
      countyId: [null],
      cityId: [null],

      country: [null],
      state: [null],
      county: [null],
      city: [null],
    });
  }

  writeValue(value: Places) {
    const countryId = get(value, 'countryId');
    const country = get(value, 'country');
    const stateId = get(value, 'stateId', null);
    const state = get(value, 'state', null);
    const countyId = get(value, 'countyId', null);
    const county = get(value, 'county', null);
    const cityId = get(value, 'cityId', null);
    const city = get(value, 'city', null);

    this.globalData = {
      countryId,
      country,
      stateId,
      state,
      countyId,
      county,
      cityId,
      city,
    };

    this.byPatchValue({
      countryId,
      country,
      stateId,
      state,
      countyId,
      county,
      cityId,
      city,
    });

    if (countryId && !country) {
      const findCountry = this.countries.find(({ id }) => id === countryId);
      if (findCountry) {
        this.countryControl.setValue(findCountry.name, { emitEvent: false });
        this.countryIdControl.setValue(countryId);
      }
    }

    if (state) {
      this.states = [{ id: stateId, name: state }];
    }

    if (county) {
      this.counties = [{ id: countyId, name: county }];
    }

    if (city) {
      this.cities = [{ id: cityId, name: city }];
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate() {
    if (this.ngValidators) {
      return (
        this.form.invalid && {
          invalid: true,
        }
      );
    }
  }

  get countryIdControl() {
    return this.form.get('countryId');
  }

  get countryControl(): UntypedFormControl {
    return this.form.get('country') as UntypedFormControl;
  }

  get stateControl() {
    return this.form.get('state');
  }

  get stateIdControl() {
    return this.form.get('stateId');
  }

  get countyControl() {
    return this.form.get('county');
  }

  get countyIdControl() {
    return this.form.get('countyId');
  }

  get cityControl() {
    return this.form.get('city');
  }

  get cityIdControl() {
    return this.form.get('cityId');
  }

  ngAfterViewInit() {
    this.subs.add(
      this.countries$.subscribe((countries) => {
        this.countries = countries;
        const { countryId, country } = this.form.value as Places;
        if (countryId && !country) {
          const findCountry = countries.find(({ id }) => id === 1);
          if (findCountry) {
            const countryName = findCountry.name;
            this.form.patchValue({
              country: countryName,
            });
          }
        }
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngOnInit(): void {
    this.subs.add(
      this.searcCity$
        .pipe(
          filter((value) => !!value),
          debounceTime(300),
        )
        .subscribe((value) => {
          this.onSearchCity(value);
        }),
    );

    this.store.dispatch(CountriesStoreActions.loadRequest());

    this.subs.add(
      this.form.valueChanges.pipe(debounceTime(300)).subscribe(() => {
        this.emitNewData();
      }),
    );

    this.subs.add(
      this.countryIdControl.valueChanges.subscribe((countryId) => {
        this.form.patchValue({
          stateId: null,
          countyId: null,
          cityId: null,
        });

        this.states = [];
        this.counties = [];
        this.cities = [];

        this.changeDetectorRef.detectChanges();

        if (!countryId) {
          return;
        }

        this.onLoadStates(countryId);
      }),
    );

    this.subs.add(
      this.stateIdControl.valueChanges.subscribe((stateId) => {
        const countryId = this.countryIdControl.value;

        this.form.patchValue(
          {
            countyId: null,
            cityId: null,
          },
          { emitEvent: false, onlySelf: true },
        );

        this.counties = [];
        this.cities = [];

        this.changeDetectorRef.detectChanges();
        if (!stateId || !countryId) {
          return;
        }

        this.onLoadCounties(countryId, stateId);
      }),
    );

    this.subs.add(
      this.countyIdControl.valueChanges.subscribe((countyId) => {
        const countryId = this.countryIdControl.value;
        const stateId = this.stateIdControl.value;

        this.form.patchValue(
          {
            cityId: null,
          },
          { emitEvent: false, onlySelf: true },
        );

        this.cities = [];

        this.changeDetectorRef.detectChanges();

        if (!countyId || !countryId || !stateId) {
          return;
        }

        this.onLoadCities(countryId, stateId, countyId);
      }),
    );

    this.subs.add(
      this.cityIdControl.valueChanges.subscribe((cityId) => {
        if (!cityId) {
          const isCityVisible = this.enabledInputs?.city;

          const { stateId, state, countyId, county } = this.form.value;

          this.form.patchValue({
            stateId: isCityVisible ? null : stateId,
            countyId: isCityVisible ? null : countyId,
            state: isCityVisible ? null : state,
            county: isCityVisible ? null : county,
          });

          this.counties = [];
          this.cities = [];
        }

        const foundCity = this.cities.find(({ id }) => +id === +cityId);

        if (!foundCity) {
          this.changeDetectorRef.detectChanges();
          return;
        }

        const { mapping } = foundCity;

        if (!mapping) {
          this.changeDetectorRef.detectChanges();
          return;
        }

        const { country, state, county } = mapping;

        this.form.patchValue({ countryId: country.id });
        this.form.patchValue({ stateId: state.id });
        this.form.patchValue({ countyId: county.id });

        // !IMPORTANT: RISOLVE IL LOOP
        this.form.patchValue(
          { cityId },
          {
            emitEvent: false,
            onlySelf: true,
          },
        );

        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onLanchSearchCity(cityName: string) {
    this.searcCity$.next(cityName);
  }

  onSearchCity(cityName: string) {
    this.citySearched = cityName;

    if (this.stateIdControl.value && this.countyIdControl.value) {
      return;
    }

    this.searchCity(cityName, this.countryIdControl.value);
  }

  onSearchNewPlace() {
    this.searchNewPlace();
  }

  private onLoadStates(countryId: number) {
    if (!countryId) {
      return;
    }
    this.subs.add(
      this.placesService
        .loadStates(countryId)
        .pipe(debounceTime(300))
        .subscribe((states: IResponseSuccess<IPlace[]>) => {
          this.states = states.data;
          this.emitNewData();
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  private onLoadCounties(countryId: number, stateId: number) {
    if (!countryId || !stateId) {
      return;
    }
    this.subs.add(
      this.placesService
        .loadCounties(countryId, stateId)
        .pipe(debounceTime(300))
        .subscribe((counties: IResponseSuccess<IPlace[]>) => {
          this.counties = counties.data;
          this.emitNewData();
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  private onLoadCities(countryId: number, stateId: number, countyId: number) {
    if (!countryId || !stateId || !countyId) {
      return;
    }
    this.subs.add(
      this.placesService
        .loadCities(countryId, stateId, countyId)
        .pipe(debounceTime(300))
        .subscribe((cities: IResponseSuccess<IPlace[]>) => {
          this.cities = cities.data;
          this.emitNewData();
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  private searchCity(cityName: string, countryId: number) {
    if (!countryId || !this.countryIdControl.value) {
      return;
    }
    this.subs.add(
      this.placesService
        .searchPlace('city', cityName, { country_id: countryId })
        .pipe(debounceTime(300))
        .subscribe((response: IResponseSuccess<any[]>) => {
          this.cities = response.data.map(({ id, value, ...mapping }) => ({
            id,
            name: value,
            mapping,
          }));
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  private searchNewPlace() {
    if (!this.citySearched || !this.countryIdControl.value) {
      return;
    }

    this.searchLoading = true;

    const citySearched = this.citySearched;
    this.citySearched = null;

    this.subs.add(
      this.placesService
        .searchNewPlace({
          country: this.countries.find(
            ({ id }) => id === this.countryIdControl.value,
          ).name,
          city: citySearched,
          forceRemoteMapping: true,
        })
        .subscribe((response: IResponseSuccess) => {
          this.searchLoading = false;
          if (!response.data.length) {
            this.notifications.warning(
              this.translate.instant('not_found_message', {
                param: this.translate.instant('city'),
              }),
            );
          }
          this.cities = response.data.map(({ city, ...mapping }) => ({
            id: city.id,
            name: city.name,
            mapping,
          }));
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  private emitNewData() {
    const countries = this.countries;
    const { countryId, stateId, countyId, cityId } = this.form.value as Places;

    const country = (countries || []).find((c) => c.id === countryId);
    const state = this.states.find((s) => s.id === stateId);
    const county = this.counties.find((c) => c.id === countyId);
    const city = this.cities.find((c) => c.id === cityId);
    const county_code = county && county.county_code;
    const country_code = country && country.country_code;

    const data = {
      country: country ? country.name : null,
      state: state ? state.name : null,
      county: county ? county.name : null,
      city: city ? city.name : null,
      zip_code: city ? city.zip_code : null,
      countryId,
      stateId,
      cityId,
      countyId,
      county_code,
      country_code,
    };

    if (
      (countryId && !data.country) ||
      (stateId && !data.state) ||
      (countyId && !data.county) ||
      (cityId && !data.city)
    ) {
      return;
    }

    if (this.globalData) {
      const newData: Partial<Places> = Object.keys(this.globalData).reduce(
        (acc, curr) => {
          acc = {
            ...acc,
            [curr]: data[curr],
          };
          return acc;
        },
        {},
      );

      if (isEqual(newData, this.globalData)) {
        return;
      }
      this.globalData = null;
    }

    // condizione da rimuovere appena implementato autocompletamento nelle vecchie implementazioni
    if (!this.byIsEmpty(data)) {
    }
    this.changePlace.emit(data);

    if (!this.onChange) {
      return;
    }

    this.onChange({
      countryId,
      stateId,
      cityId,
      countyId,
      country: country ? country.name : null,
      state: state ? state.name : null,
      county: county ? county.name : null,
      city: city ? city.name : null,
    });
    this.changeDetectorRef.detectChanges();
  }

  onLoadCitieLaucher(expand: boolean) {
    if (expand) {
      if (this.cities.length <= 1) {
        const { countryId, stateId, countyId } = this.form.value as Places;
        this.onLoadCities(countryId, stateId, countyId);
      }
    }
  }

  onLoadCountiesLaucher(expand: boolean) {
    if (expand) {
      if (this.counties.length <= 1) {
        const { countryId, stateId } = this.form.value as Places;
        this.onLoadCounties(countryId, stateId);
      }
    }
  }

  onLoadStatesLaucher(expand: boolean) {
    if (expand) {
      if (this.states.length <= 1) {
        const { countryId } = this.form.value as Places;
        this.onLoadStates(countryId);
      }
    }
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.form.disable({ emitEvent: false })
      : this.form.enable({ emitEvent: false });
  }

  byIsEmpty(obj: any) {
    if (!obj) {
      return true;
    }
    if (!Object.keys(obj).length) {
      return true;
    }
    const somefilled = Object.keys(obj).some((key) => obj[key]);
    if (somefilled) {
      return false;
    }
    return true;
  }

  byPatchValue(places: Places) {
    const keys: PlacesKey[] = [
      'countryId',
      'stateId',
      'countyId',
      'cityId',
      'country',
      'state',
      'county',
      'city',
    ];
    const sortedkeys = sortBy(Object.keys(places), (item) => {
      return keys.indexOf(item as PlacesKey);
    });

    const oldValues = this.form.value as Places;
    sortedkeys.forEach((key) => {
      const placeName = places[key.slice(0, key.length - 2)];
      if (oldValues[key] !== places[key] || !placeName) {
        if (!key.includes('Id')) {
          this.form.get(key).setValue(places[key], { emitEvent: false });
        } else {
          this.form
            .get(key)
            .setValue(places[key], { emitEvent: placeName ? false : true });
        }
      }
    });
    if (this.autoHide) {
      this.enabledInputs = autoHidePlacesFn(places);
    } else if (this.enableInputsFunction) {
      this.enabledInputs = this.enableInputsFunction(places);
    }
  }

  get showCountryValidationMessage() {
    return (
      this.countryIdControl.invalid &&
      (this.countryIdControl.touched || this.countryIdControl.dirty)
    );
  }

  get showCityValidationMessage() {
    return (
      this.cityIdControl.invalid &&
      (this.cityIdControl.touched || this.cityIdControl.dirty)
    );
  }

  get showStateValidationMessage() {
    return (
      this.stateIdControl.invalid &&
      (this.stateIdControl.touched || this.stateIdControl.dirty)
    );
  }

  get showCountyValidationMessage() {
    return (
      this.countyIdControl.invalid &&
      (this.countyIdControl.touched || this.countyIdControl.dirty)
    );
  }

  get cityServerSearch(): boolean {
    return !(this.stateIdControl.value && this.countyIdControl.value);
  }
}
