<ng-template #countryTpl>
  <div [formGroup]="form">
    <nz-form-item [ngClass]="{ hiddenInput: !enabledInputs['country'] }">
      <nz-form-label
        class="by-mr-1 place-select-wd"
        [nzRequired]="required || isRequiredField(form.get('countryId'))"
        >{{
          (overrideLabel && overrideLabel.country
            ? overrideLabel.country
            : 'country'
          )
            | translate
            | capitalize
        }}
      </nz-form-label>
      <nz-form-control
        [nzValidateStatus]="showCountryValidationMessage ? 'error' : 'success'"
        [nzErrorTip]="
          'validation_form.required'
            | translate
              : {
                  param: 'country' | translate
                }
        "
      >
        <nz-select
          class="places-form-select place-select-wd"
          nzShowSearch
          formControlName="countryId"
          nzAllowClear
          [nzClearIcon]="clearAllIconTpl"
        >
          @for (country of countries; track country.id) {
            <nz-option
              [nzLabel]="country.name"
              [nzValue]="country.id"
            ></nz-option>
          }
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</ng-template>
<ng-template #cityTpl>
  <div [formGroup]="form">
    <nz-form-item [ngClass]="{ hiddenInput: !enabledInputs['city'] }">
      <nz-form-label
        class="by-mr-1 place-select-wd"
        [nzRequired]="required || isRequiredField(form.get('cityId'))"
        >{{
          (overrideLabel && overrideLabel.city ? overrideLabel.city : 'city')
            | translate
            | capitalize
        }}</nz-form-label
      >
      <nz-form-control
        [nzValidateStatus]="showCityValidationMessage ? 'error' : 'success'"
        [nzErrorTip]="
          'validation_form.required'
            | translate
              : {
                  param: 'city' | translate
                }
        "
      >
        <nz-select
          class="places-form-select place-select-wd"
          nzShowSearch
          nzServerSearch
          formControlName="cityId"
          (nzOnSearch)="onLanchSearchCity($event)"
          [nzAllowClear]="allowClear"
          (nzOpenChange)="onLoadCitieLaucher($event)"
          nzAllowClear
          [nzClearIcon]="clearCityIconTpl"
        >
          <nz-option
            nzCustomContent
            [nzLabel]="citySearched"
            [nzValue]="citySearched"
          >
            <by-spin [nzSpinning]="searchLoading">
              <a class="link-search" (click)="onSearchNewPlace()">
                <i class="far fa-search"></i> ...{{ citySearched }}</a
              >
            </by-spin>
          </nz-option>
          @for (city of cities; track city.id) {
            <nz-option [nzLabel]="city.name" [nzValue]="city.id"></nz-option>
          }
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</ng-template>
<ng-template #countyTpl>
  <div [formGroup]="form">
    <nz-form-item [ngClass]="{ hiddenInput: !enabledInputs['county'] }">
      <nz-form-label
        class="by-mr-1 place-select-wd"
        [nzRequired]="required || isRequiredField(form.get('countyId'))"
        >{{
          (overrideLabel && overrideLabel.county
            ? overrideLabel.county
            : 'county'
          )
            | translate
            | capitalize
        }}</nz-form-label
      >
      <nz-form-control
        [nzValidateStatus]="showCountyValidationMessage ? 'error' : 'success'"
        [nzErrorTip]="
          'validation_form.required'
            | translate
              : {
                  param: 'county' | translate
                }
        "
      >
        <nz-select
          class="places-form-select place-select-wd"
          nzShowSearch
          formControlName="countyId"
          (nzOpenChange)="onLoadCountiesLaucher($event)"
          nzAllowClear
          [nzClearIcon]="clearCountyIconTpl"
        >
          @for (county of counties; track county.id) {
            <nz-option
              [nzLabel]="county.name"
              [nzValue]="county.id"
            ></nz-option>
          }
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</ng-template>
<ng-template #stateTpl>
  <div [formGroup]="form">
    <nz-form-item [ngClass]="{ hiddenInput: !enabledInputs['state'] }">
      <nz-form-label
        class="by-mr-1 place-select-wd"
        [nzRequired]="required || isRequiredField(form.get('stateId'))"
        >{{
          (overrideLabel && overrideLabel.state ? overrideLabel.state : 'state')
            | translate
            | capitalize
        }}</nz-form-label
      >
      <nz-form-control
        [nzValidateStatus]="showStateValidationMessage ? 'error' : 'success'"
        [nzErrorTip]="
          'validation_form.required'
            | translate
              : {
                  param: 'state' | translate
                }
        "
      >
        <nz-select
          class="places-form-select place-select-wd"
          nzShowSearch
          formControlName="stateId"
          (nzOpenChange)="onLoadStatesLaucher($event)"
          nzAllowClear
          [nzClearIcon]="clearStateIconTpl"
        >
          @for (state of states; track state.id) {
            <nz-option [nzLabel]="state.name" [nzValue]="state.id"></nz-option>
          }
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>
</ng-template>
<div class="places-select-container">
  @if (!nzLayout) {
    <form
      nz-form
      [nzLayout]="'vertical'"
      [formGroup]="form"
      class="{{ customFormClass }}"
      [ngStyle]="{ display: formDisplay }"
    >
      @if (!templateMode) {
        <div nz-row nzGutter="16">
          <div
            nz-col
            [nzXs]="24"
            [nzMd]="collapsed ? 12 : 24"
            [ngClass]="{ hiddenInput: !enabledInputs['country'] }"
          >
            <nz-form-item>
              <nz-form-label
                [nzRequired]="
                  required || isRequiredField(form.get('countryId'))
                "
                nz-col
                [nzXs]="24"
                [nzMd]="24"
                >{{
                  (overrideLabel && overrideLabel.country
                    ? overrideLabel.country
                    : 'country'
                  )
                    | translate
                    | capitalize
                }}</nz-form-label
              >
              <nz-form-control
                nz-col
                [nzXs]="24"
                [nzMd]="24"
                class="by-w-100"
                [nzValidateStatus]="
                  showCountryValidationMessage ? 'error' : 'success'
                "
                [nzErrorTip]="
                  'validation_form.required'
                    | translate
                      : {
                          param: 'country' | translate
                        }
                "
              >
                <nz-select
                  class="places-form-select place-select-wd"
                  nzShowSearch
                  formControlName="countryId"
                  [nzShowArrow]="true"
                  nzAllowClear
                  [nzClearIcon]="clearAllIconTpl"
                >
                  @for (country of countries; track country.id) {
                    <nz-option
                      [nzLabel]="country.name"
                      [nzValue]="country.id"
                    ></nz-option>
                  }
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="24"
            [nzMd]="collapsed ? 12 : 24"
            [ngClass]="{ hiddenInput: !enabledInputs['city'] }"
          >
            <nz-form-item style="width: 100%">
              <nz-form-label
                [nzRequired]="required || isRequiredField(form.get('cityId'))"
                >{{
                  (overrideLabel && overrideLabel.city
                    ? overrideLabel.city
                    : 'city'
                  )
                    | translate
                    | capitalize
                }}</nz-form-label
              >
              <nz-form-control
                [nzValidateStatus]="
                  showCityValidationMessage ? 'error' : 'success'
                "
                [nzErrorTip]="
                  'validation_form.required'
                    | translate
                      : {
                          param: 'city' | translate
                        }
                "
                style="width: 100%"
              >
                <nz-select
                  class="places-form-select place-select-wd"
                  nzShowSearch
                  [nzServerSearch]="cityServerSearch"
                  formControlName="cityId"
                  (nzOnSearch)="onLanchSearchCity($event)"
                  (nzOpenChange)="onLoadCitieLaucher($event)"
                  nzAllowClear
                  [nzClearIcon]="clearCityIconTpl"
                >
                  <nz-option
                    nzCustomContent
                    [nzLabel]="citySearched"
                    [nzValue]="citySearched"
                  >
                    <by-spin [nzSpinning]="searchLoading">
                      <a class="link-search" (click)="onSearchNewPlace()">
                        <i class="far fa-search"></i> ...{{ citySearched }}</a
                      >
                    </by-spin>
                  </nz-option>
                  @for (city of cities; track city.id) {
                    <nz-option
                      [nzLabel]="city.name"
                      [nzValue]="city.id"
                    ></nz-option>
                  }
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="24"
            [nzMd]="collapsed ? 12 : 24"
            [ngClass]="{ hiddenInput: !enabledInputs['county'] }"
          >
            <nz-form-item style="width: 100%">
              <nz-form-label
                [nzRequired]="required || isRequiredField(form.get('countyId'))"
                >{{
                  (overrideLabel && overrideLabel.county
                    ? overrideLabel.county
                    : 'county'
                  )
                    | translate
                    | capitalize
                }}</nz-form-label
              >
              <nz-form-control
                style="width: 100%"
                [nzValidateStatus]="
                  showCountyValidationMessage ? 'error' : 'success'
                "
                [nzErrorTip]="
                  'validation_form.required'
                    | translate
                      : {
                          param: 'county' | translate
                        }
                "
              >
                <nz-select
                  class="places-form-select place-select-wd"
                  nzShowSearch
                  formControlName="countyId"
                  (nzOpenChange)="onLoadCountiesLaucher($event)"
                  nzAllowClear
                  [nzClearIcon]="clearCountyIconTpl"
                >
                  @for (county of counties; track county.id) {
                    <nz-option
                      [nzLabel]="county.name"
                      [nzValue]="county.id"
                    ></nz-option>
                  }
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            [nzXs]="24"
            [nzMd]="collapsed ? 12 : 24"
            [ngClass]="{ hiddenInput: !enabledInputs['state'] }"
          >
            <nz-form-item style="width: 100%">
              <nz-form-label
                [nzRequired]="required || isRequiredField(form.get('stateId'))"
                >{{
                  (overrideLabel && overrideLabel.state
                    ? overrideLabel.state
                    : 'state'
                  )
                    | translate
                    | capitalize
                }}</nz-form-label
              >
              <nz-form-control
                [nzValidateStatus]="
                  showStateValidationMessage ? 'error' : 'success'
                "
                [nzErrorTip]="
                  'validation_form.required'
                    | translate
                      : {
                          param: 'state' | translate
                        }
                "
                style="width: 100%"
              >
                <nz-select
                  class="places-form-select place-select-wd"
                  nzShowSearch
                  formControlName="stateId"
                  (nzOpenChange)="onLoadStatesLaucher($event)"
                  nzAllowClear
                  [nzClearIcon]="clearStateIconTpl"
                >
                  @for (state of states; track state.id) {
                    <nz-option
                      [nzLabel]="state.name"
                      [nzValue]="state.id"
                    ></nz-option>
                  }
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      }
    </form>
  }

  @if (nzLayout) {
    @if (!templateMode) {
      <form
        nz-form
        [ngStyle]="{ display: formDisplay }"
        [nzLayout]="nzLayout"
        [formGroup]="form"
        class="{{ customFormClass }} by-w-100"
      >
        <ng-container *ngTemplateOutlet="countryTpl"> </ng-container>
        <ng-container *ngTemplateOutlet="stateTpl"></ng-container>
        <ng-container *ngTemplateOutlet="countyTpl"></ng-container>
        <ng-container *ngTemplateOutlet="cityTpl"> </ng-container>
      </form>
    }
  }
</div>

<ng-template #clearCityIconTpl>
  <ng-container
    *ngTemplateOutlet="
      clearIconTpl;
      context: {
        message: clearCityMsgTpl
      }
    "
  >
  </ng-container>
  <ng-template #clearCityMsgTpl>
    {{ 'clean_up' | translate | upperfirst }}
    {{ 'state' | translate }}, {{ 'county' | translate }}, <br />
    {{ 'city' | translate }}.
  </ng-template>
</ng-template>

<ng-template #clearStateIconTpl>
  <ng-container
    *ngTemplateOutlet="
      clearIconTpl;
      context: {
        message: clearStateMsgTpl
      }
    "
  >
  </ng-container>
  <ng-template #clearStateMsgTpl>
    {{ 'clean_up' | translate | capitalize }}
    {{ 'state' | translate }}, {{ 'county' | translate }}, <br />
    {{ 'city' | translate }}.
  </ng-template>
</ng-template>

<ng-template #clearCountyIconTpl>
  <ng-template #clearCountyMsgTpl>
    {{ 'clean_up' | translate | capitalize }}
    {{ 'county' | translate }}, {{ 'city' | translate }}.
  </ng-template>
  <ng-container
    *ngTemplateOutlet="
      clearIconTpl;
      context: {
        message: clearCountyMsgTpl
      }
    "
  >
  </ng-container>
</ng-template>

<ng-template #clearAllIconTpl let-selected>
  <ng-template #messageTpl>
    {{ 'clear_swap_message' | translate | upperfirst }}
  </ng-template>
  <ng-container
    *ngTemplateOutlet="
      clearIconTpl;
      context: {
        message: messageTpl
      }
    "
  >
  </ng-container>
</ng-template>

<ng-template #clearIconTpl let-message="message">
  <button
    style="border-color: #d9363e"
    nzSize="small"
    nz-button
    nzType="primary"
    nzDanger
    nz-tooltip
    [nzTooltipTitle]="message"
    nzTooltipPlacement="top"
  >
    {{ 'cancel' | translate | capitalize }}
  </button>
</ng-template>
